import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Paper, Grid, Typography, Tooltip } from '@material-ui/core';
import { Form } from 'formik';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';
import AsyncSelect from 'components/AsyncSelect';

import { loadEconomicActivities } from '../../../../store/thunk';

import { availableTaxTypes, availableIssChargeabilities } from './resources';
import { checkedValueByType } from 'helpers/common';

export default function CityHallRegistration({ formikProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { isLoading } = useSelector(state => state.businessInfo);

  const {
    configType,
    custom_settings,
    tooltips,
    basicInfoErrors,
    tables: {
      taxationRegime,
      taxationRegimeSpecial
    }
  } = useSelector(state => state.provisionalReceiptConfig);

  const isSameCnpj = +configType == 0;

  useEffect(() => {
    dispatch(loadEconomicActivities(userId));
  }, []);

  return(
    <>
      <Paper
        container
        style={{
          padding: 25,
          backgroundColor: '#FFFFFF',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}
      >
        <Grid item xs={12}>
          <Form>
            <Loader isLoading={isLoading}>
              <Grid item xs={12}>
                <Loader isLoading={isLoading}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <AsyncSelect
                        disabled
                        name="cityCode"
                        label="Munícipio"
                        value={formikProps.values.cityCode}
                        error={formikProps.errors?.cityCode || basicInfoErrors?.cityCode}
                        helperText={formikProps.errors?.cityCode || basicInfoErrors?.cityCode}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <Tooltip
                        placement="top"
                        title={
                          <Typography style={{ fontSize: 15 }}>
                            {tooltips?.tooltipMunicipalSubscription?.replace(' (somente números)', '')}
                          </Typography>
                        }
                      >
                        <Input
                          disabled={isSameCnpj}
                          id="municipalSubscription"
                          type="text"
                          label="Inscrição municipal"
                          value={formikProps.values.municipalSubscription}
                          error={formikProps.errors.municipalSubscription || basicInfoErrors?.municipalSubscription}
                          helperText={formikProps.errors.municipalSubscription || basicInfoErrors?.municipalSubscription}
                          onChange={formikProps.handleChange}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <Input
                        disabled={isSameCnpj}
                        id="stateSubscription"
                        type="text"
                        label="Inscrição estadual"
                        value={formikProps.values.stateSubscription}
                        error={formikProps.errors.stateSubscription}
                        helperText={formikProps.errors.stateSubscription}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Select
                        disabled={isSameCnpj}
                        name="taxationRegime"
                        label="Regime tributário"
                        options={taxationRegime}
                        value={+formikProps.values.taxationRegime || ''}
                        error={formikProps.errors.taxationRegime || basicInfoErrors?.taxationRegime}
                        helperText={formikProps.errors.taxationRegime || basicInfoErrors?.taxationRegime}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Tooltip
                        placement="top"
                        title={
                          <Typography style={{ fontSize: 15 }}>
                            "Adicione mais de um cartão. Ex: 0-10"
                          </Typography>
                        }
                      >
                        <Select
                          disabled={isSameCnpj}
                          name="encouraging"
                          label="Incentivador cultural"
                          options={[
                            { label: 'Sim', value: 1 },
                            { label: 'Não', value: 2 }
                          ]}
                          value={formikProps.values.encouraging}
                          error={formikProps.errors.encouraging || basicInfoErrors?.encouraging}
                          helperText={formikProps.errors.encouraging || basicInfoErrors?.encouraging}
                          onChange={formikProps.handleChange}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Select
                        disabled={isSameCnpj}
                        name="taxIncentive"
                        label="Incentivo tributário"
                        options={[
                          { label: 'Sim', value: '1' },
                          { label: 'Não', value: '0' }
                        ]}
                        value={formikProps.values.taxIncentive?.toString()}
                        error={formikProps.errors.taxIncentive || basicInfoErrors?.taxIncentive}
                        helperText={formikProps.errors.taxIncentive || basicInfoErrors?.taxIncentive}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Select
                        disabled={isSameCnpj}
                        name="taxType"
                        label="Tipo de tributação"
                        options={availableTaxTypes}
                        value={formikProps.values.taxType?.toString()}
                        error={formikProps.errors.taxType}
                        helperText={formikProps.errors.taxType}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: 15 }}>
                            "Adicione mais de um cartão. Ex: 0-10"
                          </Typography>
                        }
                        placement="top"
                      >
                        <Select
                          disabled={isSameCnpj}
                          name="simpleNational"
                          label="Simples Nacional"
                          options={[
                            { label: 'Sim', value: 1 },
                            { label: 'Não', value: 2 }
                          ]}
                          value={formikProps.values.simpleNational}
                          error={formikProps.errors.simpleNational || basicInfoErrors?.simpleNational}
                          helperText={formikProps.errors.simpleNational || basicInfoErrors?.simpleNational}
                          onChange={formikProps.handleChange}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Select
                        disabled={isSameCnpj}
                        name="issChargeability"
                        label="ISS"
                        options={availableIssChargeabilities}
                        value={formikProps.values.issChargeability}
                        error={formikProps.errors.issChargeability}
                        helperText={formikProps.errors.issChargeability}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Tooltip
                        placement="top"
                        title={
                          <Typography style={{ fontSize: 15 }}>
                            "Adicione mais de um cartão. Ex: 0-10"
                          </Typography>
                        }
                      >
                        <Select
                          disabled={isSameCnpj}
                          name="issRetain"
                          label="ISS retido"
                          options={[
                            { label: 'Sim', value: 1 },
                            { label: 'Não', value: 2 }
                          ]}
                          value={formikProps.values.issRetain}
                          error={formikProps.errors.issRetain}
                          helperText={formikProps.errors.issRetain}
                          onChange={formikProps.handleChange}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        disabled={isSameCnpj}
                        name="issZeroAmount"
                        label="ISS valor zero"
                        options={[
                          { label: 'Sim', value: '1' },
                          { label: 'Não', value: '0' }
                        ]}
                        value={formikProps.values.issZeroAmount?.toString()}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    {checkedValueByType(custom_settings?.requiredServiceDescription, 'boolean') && (
                      <Grid item sm={6} xs={12}>
                        <Input
                          disabled={isSameCnpj}
                          id="description"
                          type="text"
                          label="Descrição do serviço"
                          maxLength={100}
                          value={formikProps.values.description || ""}
                          error={formikProps.errors.description}
                          helperText={formikProps.errors.description}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                    )}
                    {checkedValueByType(custom_settings?.requiredMunicipalServiceCode, 'boolean') && (
                      <Grid item sm={6} xs={12}>
                        <Tooltip
                          placement="top"
                          title={
                            <Typography style={{ fontSize: 15 }}>
                              {tooltips?.tooltipMunicipalServiceCode}
                            </Typography>
                          }
                        >
                          <Input
                            disabled={isSameCnpj}
                            id="serviceCode"
                            type="text"
                            label="Código do serviço"
                            maxLength={50}
                            value={formikProps.values.serviceCode || ""}
                            error={formikProps.errors.serviceCode ||  basicInfoErrors?.taxCode}
                            helperText={formikProps.errors.serviceCode || basicInfoErrors?.taxCode}
                            onChange={formikProps.handleChange}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                    {checkedValueByType(custom_settings?.requiredCnae, 'boolean') && (
                      <Grid item sm={6} xs={12}>
                        <Input
                          disabled={isSameCnpj}
                          id="code"
                          type="text"
                          label="CNAE"
                          maxLength={15}
                          value={formikProps.values.code || ""}
                          error={formikProps.errors.code}
                          helperText={formikProps.errors.code}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                    )}
                    <Grid item sm={6} xs={12}>
                      <Input
                        disabled={isSameCnpj}
                        id="aliquot"
                        label="Alíquota"
                        type="money"
                        value={formikProps.values.aliquot?.replaceAll('.', ',') || ""}
                        error={formikProps.errors.aliquot || basicInfoErrors?.aliquot}
                        helperText={formikProps.errors.aliquot || basicInfoErrors?.aliquot}
                        onChange={event => {
                          const formattedValue = event.target.value?.replaceAll('.', '');
                          formikProps.setFieldValue('aliquot', formattedValue);
                        }}
                      />
                    </Grid>
                    {checkedValueByType(custom_settings?.requiredServiceItem, 'boolean') && (
                      <Grid item sm={6} xs={12}>
                        <Tooltip
                          placement="top"
                          title={
                            <Typography style={{ fontSize: 15 }}>
                              {tooltips?.tooltipServiceItem}
                            </Typography>
                          }
                        >
                          <Input
                            disabled={isSameCnpj}
                            id="serviceItem"
                            type="text"
                            label="Item Lista Servico"
                            maxLength={50}
                            value={formikProps.values.serviceItem || ""}
                            error={formikProps.errors.serviceItem || basicInfoErrors?.serviceCode}
                            helperText={formikProps.errors.serviceItem || basicInfoErrors?.serviceCode}
                            onChange={formikProps.handleChange}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                    {checkedValueByType(custom_settings?.requiredSpecialTaxRegime, 'boolean') && (
                      <Grid item xs={12} md={6}>
                        <Select
                          disabled={isSameCnpj}
                          name="taxationRegimeSpecial"
                          label="Regime tributário especial"
                          options={taxationRegimeSpecial}
                          value={+formikProps.values.taxationRegimeSpecial || ''}
                          error={formikProps.errors.taxationRegimeSpecial}
                          helperText={formikProps.errors.taxationRegimeSpecial}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <Tooltip
                        interactive
                        leaveDelay={250}
                        placement="top"
                        title={
                          <a
                            style={{ color: '#FFFFFF', textDecoration: 'none' }}
                            target="_blank"
                            href="https://blog.tecnospeed.com.br/nfse-nacional-tudo/#:~:text=no%2520seu%2520ERP-,O%2520que%2520%25C3%25A9%2520NFS%252De%2520Nacional%253F,Receita%2520Federal%2520ou%2520prefeituras%2520municipais"
                          >
                            <Typography>Link explicativo</Typography>
                          </a>
                        }
                      >
                        <div>
                          <Select
                            disabled={isSameCnpj}
                            name="nfseNational"
                            label="Padrão Nacional"
                            options={[
                              { label: 'Sim', value: '1' },
                              { label: 'Não', value: '0' }
                            ]}
                            value={formikProps.values.nfseNational?.toString()}
                            error={formikProps.errors.nfseNational}
                            helperText={formikProps.errors.nfseNational}
                            onChange={formikProps.handleChange}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Loader>
              </Grid>
            </Loader>
          </Form>
        </Grid>
      </Paper>
    </>
  );
}

CityHallRegistration.defaultProps = {
  formikProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      establishmentName: '',
      companyName: '',
      documentId: '',
      phone: '',
      email: '',
      postalCode: '',
      street: '',
      number: 0,
      complement: '',
      district: '',
      city: '',
      state: '',
      vacanciesMarks: '',
      encouraging: '',
      simpleNational: '',
      issRetain: ''
    },
    errors: {
      companyName: false,
      documentId: false,
      phone: false,
      email: false,
      postalCode: false,
      street: false,
      number: false,
      complement: false,
      district: false,
      city: false,
      state: false,
      vacanciesMarks: false,
      encouraging: false,
      simpleNational: false,
      issRetain: false
    }
  }
};

CityHallRegistration.propTypes = {
  formikProps: PropTypes.shape()
};